import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"

const AboutPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title },
      html,
    },
  },
}) => {
  return (
    <Layout title={title} className="about">
      <section
        className="about-page"
        style={{ maxWidth: 720, margin: "0 auto" }}
      >
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </section>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({}),
}

export const pageQuery = graphql`
  query {
    markdownRemark {
      html
      frontmatter {
        title
      }
    }
  }
`

export default AboutPage
